import React, {Component} from "react";
import {Redirect, Route} from "react-router-dom";
import Login from "./Login";
import Register from "./Register";
import {connect} from "react-redux";

class AuthView extends Component {

    render() {
        const {loggedUser} = this.props as any;

        if (loggedUser.ready && loggedUser.logged) {
            return <Redirect to="/app"/>;
        }

        return <div id="loading">
            <div className="main">
                <header id="header">
                    <div id="logo">Coffeefy</div>
                </header>
                <Route path="/auth/login" component={Login}/>
                <Route path="/auth/register" component={Register}/>
            </div>
            <footer id="footer">
                <div className="footer-text">
                    Copyright &copy; 2020 <a href="https://coffefreak.pl">Coffefreak.pl</a>
                </div>
            </footer>
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        loggedUser: state.user
    }
}

export default connect(mapStateToProps)(AuthView);