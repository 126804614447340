import React, {Component} from "react";
import {Redirect} from "react-router-dom";
import './Auth.css'
import {connect} from 'react-redux';

class FirstView extends Component {
    render() {
        const {loggedUser} = this.props as any;

        if (loggedUser.ready) {
            if (loggedUser.logged) {
                return <Redirect to="/app"/>;
            }
            return <Redirect to="/auth/login"/>;
        }

        return (
            <div id="loading">
                <div className="main">
                    <header id="header">
                        <div id="logo">Coffeefy</div>
                    </header>
                </div>
                <footer id="footer">
                    <div className="footer-text">
                        Copyright &copy; 2020 <a href="https://coffefreak.pl">Coffefreak.pl</a>
                    </div>
                </footer>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loggedUser: state.user
    }
}

export default connect(mapStateToProps)(FirstView);