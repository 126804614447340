import React, {ChangeEvent, Component, FormEvent} from "react";
import {Button, Form} from "react-bootstrap";
import axios from 'axios';
import env from "../env";

class Register extends Component {

    state = {
        email: "",
        password: "",
        retypePassword: ""
    }

    handleChange = (e: ChangeEvent) => {
        this.setState({
            [e.target.id]: (e.target as HTMLInputElement).value
        })
    }

    handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        axios.post(
            `${env.backend}auth/register`,
            this.state,
            {withCredentials: true})
            .then(res => {
                if (res.data.success) {
                    (this.props as any).history.push("/")
                }
            })
            .catch(err => {
                alert("Something went wrong...");
            })
    }

    render() {
        return (
            <div id="login-form">
                <Form onSubmit={this.handleSubmit}>
                    <Form.Group controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Email"
                            required
                            value={this.state.email}
                            onChange={this.handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="password">
                        <Form.Label>Hasło</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Hasło"
                            required
                            value={this.state.password}
                            onChange={this.handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="retypePassword">
                        <Form.Label>Powtórz Hasło</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Powtórz hasło"
                            required
                            value={this.state.retypePassword}
                            onChange={this.handleChange}
                        />
                    </Form.Group>
                    <Button variant="primary" onClick={this.handleSubmit}>
                        Rejestracja
                    </Button>
                </Form>
            </div>
        )
    }
}

export default Register;