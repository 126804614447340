import React, {Component} from "react";
import {Button} from "react-bootstrap";
import axios from 'axios'
import {logout} from "../actions/authActions";
import {connect} from "react-redux";
import env from "../env";


class TopMenu extends Component {
    logout = () => {
        const {history, logout} = this.props as any;

        axios.post(`${env.backend}auth/logout`, {}, {withCredentials: true})
            .then((res) => {
                if (res.data.success) {
                    logout();
                    history.push("/");
                }
            })
    }

    render() {
        return <div id="top-menu">
            <div id="logo-min"></div>
            <Button onClick={this.logout} id="logout" className="btn btn-danger">
                <i className="fa fa-power-off"></i>
            </Button>
        </div>
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => { dispatch(logout()) }
    }
}

export default connect(null, mapDispatchToProps)(TopMenu);