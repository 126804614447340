const initState = {ready: false, logged: false}

const authReducer = (state = initState as any, action) => {
    switch (action.type) {
        case 'USER_LOGGED':
            return {...state, logged: true, ready: true}
        case 'USER_NOT_LOGGED':
            return {...state, logged: false, ready: true}
        case 'LOGGED_OUT':
            return {...state, logged: false}
        default:
            console.log(action.type)
            return state;
    }
}

export default authReducer;