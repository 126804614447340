import React, {ChangeEvent, Component, FormEvent} from "react";
import {Button, Form} from "react-bootstrap";
import {Link} from "react-router-dom";
import axios from 'axios';
import {checkLogin} from "../actions/authActions";
import {connect} from 'react-redux';
import env from "../env";

class Login extends Component {

    state = {
        email: "",
        password: ""
    }

    handleChange = (e: ChangeEvent) => {
        this.setState({
            [e.target.id]: (e.target as HTMLInputElement).value
        })
    }

    handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        axios.post(
            `${env.backend}auth/login`,
            this.state,
            {withCredentials: true}
        ).then(res => {
            if (res.data.success) {
                (this.props as any).checkLogin();
            }
        }).catch((err) => {
            alert("Nieprawidłowy email lub hasło");
        })
    }

    render() {
        return (
            <div id="login-form">
                <Form onSubmit={this.handleSubmit}>
                    <Form.Group controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Email"
                            value={this.state.email}
                            onChange={this.handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="password">
                        <Form.Label>Hasło</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Hasło"
                            value={this.state.password}
                            onChange={this.handleChange}
                        />
                    </Form.Group>
                    <Button variant="primary" onClick={this.handleSubmit}>Login</Button>
                </Form>
                <ul className="links">
                    <li><Link to="/auth/register">Rejestracja</Link></li>
                    <li><Link to="/auth/forgot-password">Przypomnij hasło</Link></li>
                </ul>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        checkLogin: () => dispatch(checkLogin())
    }
}

export default connect(null, mapDispatchToProps)(Login);