import React, {Component} from "react";
import {Card} from "react-bootstrap";
import env from "../../../env";

interface EntryProps {
    entry
}

class Single extends Component<EntryProps, {}> {
    render() {
        const card = this.props.entry.image ?
            <div className="card-img-top coffee-top-img" style={{ backgroundImage: `url(${env.backend}entry/image/${this.props.entry.id}${this.props.entry.image})` }}/> : "";

        return <Card className="mb-2">
            {card}
            <Card.Body>
                <Card.Title>
                    {this.props.entry.country} - {this.props.entry.roastery} - {this.props.entry.farm}
                </Card.Title>
            </Card.Body>
        </Card>
    }
}

export default Single;