import React, {Component} from "react";
import axios from 'axios';
import Single from "./entry/Single";
import env from "../../env";

class ListEntries extends Component {
    state = {
        entries: null
    }

    componentDidMount() {
        axios.get(`${env.backend}entry/list`, {withCredentials: true})
            .then((res) => {
                this.setState({entries: res.data.data});
            })
    }

    render() {
        const entriesList = this.state.entries !== null ? (
            this.state.entries.map(entry => {
                return (
                    <Single entry={entry}/>
                )
            })
        ) : <span>Loading...</span>;
        return <div className="container">
            {entriesList}
        </div>;
    }
}

export default ListEntries;