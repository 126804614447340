import React, {Component} from "react";
import './Main.css'
import 'font-awesome/css/font-awesome.min.css';
import {BrowserRouter, Link, Redirect, Route} from "react-router-dom";
import TopMenu from "./TopMenu";
import {connect} from 'react-redux';
import AddEntry from "./coffe/AddEntry";
import ListEntries from "./coffe/ListEntries";


class AppView extends Component {
    render() {
        const {loggedUser} = this.props as any;

        if (loggedUser.ready && !loggedUser.logged) {
            return <Redirect to="/"/>;
        }

        return <div id="container">
            <TopMenu history={(this.props as any).history}/>
            <div id="content">
                <Route path="/app/add" component={AddEntry}/>
                <Route exact path="/app" component={ListEntries}/>
            </div>
            <div id="bottom-menu">
                <Link to="/app/add" className="btn btn-light">
                    <i className="fa fa-plus"></i>
                </Link>
            </div>
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        loggedUser: state.user
    }
}

export default connect(mapStateToProps)(AppView)