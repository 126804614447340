import axios from "axios";
import env from "../env";

export const checkLogin = () => {
    return (dispatch, getState) => {
        axios.get(`${env.backend}auth/user`, {withCredentials: true}).then(res => {
            if(res.data.success) {
                dispatch({type: 'USER_LOGGED'})
            } else {
                dispatch({type: 'USER_NOT_LOGGED'})
            }
        }).catch(e => {
            dispatch({type: 'USER_NOT_LOGGED'})
        })
    }
}

export const logout = () => { return { type: 'LOGGED_OUT' }}