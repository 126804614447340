import React, {Component} from 'react';
import './App.css';
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import FirstView from "./auth/FirstView";
import {connect} from 'react-redux';
import {checkLogin} from "./actions/authActions";
import AuthView from "./auth/AuthView";
import AppView from "./app/AppView";

class App extends Component {
    componentDidMount() {
        (this.props as any).checkLogin();
    }

    render() {
        return (
            <div className="app">
                <BrowserRouter>
                    <Switch>
                        <Route exact path="/" component={FirstView}/>
                        <Route path="/auth" component={AuthView}/>
                        <Route path="/app" component={AppView}/>
                        <Route component={FirstView}/>
                    </Switch>
                </BrowserRouter>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        checkLogin: () => dispatch(checkLogin())
    }
}

export default connect(null, mapDispatchToProps)(App);
