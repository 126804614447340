import React, {ChangeEvent, Component, FormEvent} from "react";
import {Button, Form} from "react-bootstrap";
import axios from "axios";
import env from "../../env";

class AddEntry extends Component {
    state = {
        country: "",
        roastery: "",
        farm: "",
        process: "",
        date: "",
        brewing: "",
        thickness: "",
        weight: "",
        water: "",
        preinfusion: "",
        volume: "",
        totalTime: "",
        notes: "",
        score: "",
        image: "",
        selectedImage: "",
        subscores: {
            aroma: "",
            acidity: "",
            sweetness: "",
            body: "",
            flowers: "",
            aftertaste: ""
        }
    }

    back = () => {
        (this.props as any).history.push("/app");
    }

    handleChange = (e: ChangeEvent) => {
        this.setState({
            [e.target.id]: (e.target as HTMLInputElement).value
        })
    }

    imageChange = (e: ChangeEvent) => {
        const input = e.target as HTMLInputElement;

        if (input.files && input.files[0]) {
            const reader = new FileReader();

            this.setState({
                selectedImage: input.files[0]
            })

            reader.onload = (e) => {
                this.setState({
                    image: e.target.result
                })
            }

            reader.readAsDataURL(input.files[0]);
        }
    }

    handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        axios.post(
            `${env.backend}entry/add`,
            this.state,
            {withCredentials: true})
            .then(res => {
                if (res.data.success) {
                    const data = new FormData()
                    data.append("file", this.state.selectedImage)

                    axios.post(`${env.backend}entry/add/image/${res.data.data.id}`, data, {withCredentials: true})
                        .then(res => {
                            (this.props as any).history.push("/")
                        })
                        .catch(err => {
                            alert("Image upload failed :(");
                            (this.props as any).history.push("/")
                        })
                }
            })
            .catch(err => {
                alert("Something went wrong...");
            })
    }

    render() {
        const img = this.state.image.length ?
            <div className="image-preview" style={{backgroundImage: `url(${this.state.image})`}}>Image
                preview</div> : "";

        return <div>
            <Form onSubmit={this.handleSubmit}>
                <Form.Group controlId="country">
                    <Form.Label>Kraj</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Kraj"
                        required
                        value={this.state.country}
                        onChange={this.handleChange}
                    />
                </Form.Group>
                <Form.Group controlId="farm">
                    <Form.Label>Farma/Region</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Farma/Region"
                        required
                        value={this.state.farm}
                        onChange={this.handleChange}
                    />
                </Form.Group>
                <Form.Group controlId="roastery">
                    <Form.Label>Palarnia</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Palarnia"
                        required
                        value={this.state.roastery}
                        onChange={this.handleChange}
                    />
                </Form.Group>
                <Form.Group controlId="img">
                    <Form.Label>Zdjęcie</Form.Label>
                    <Form.Control
                        type="file"
                        placeholder="Zdjęcie"
                        required
                        onChange={this.imageChange}
                    />
                </Form.Group>
                {img}
                <div className="buttons">
                    <Button type="submit" variant="primary">Dodaj</Button>
                    <Button variant="danger" onClick={this.back} className="pull-right">Powrót</Button>
                </div>
            </Form>
        </div>;
    }
}

export default AddEntry;